body {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.App {
  text-align: center;
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
  height: 100%;
}

.App-header {
  height: 75px;
}

.App-header {
  width: 100%;
  height: 60px;
  background-color: white;
  font-size: calc(10px + 2vmin);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav-header {
  justify-content: space-around;
  align-items: center;
}

.display-flex {
  display: flex;
}

.flex-1 {
  flex: 1;
}

.width-100 {
  width: 100%;
}

.App-main {
  width: 100%;
  font-size: calc(10px + 2vmin);
  flex: auto;
  overflow: auto;
}

@media only screen and (min-width: 768px) {
  .App-main {
    width: 100%;
    font-size: calc(10px + 2vmin);
    flex: auto;
    overflow: auto;
    height: calc(100vh - 130px);
  }
}

.App-footer {
  /* width: 100%;
  height: 70px; */
  font-size: calc(10px + 2vmin);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  flex-wrap: wrap;
  gap: 10px;
  padding: 5px;
}
.app-footer-background {
  background-color: #1c2536;
}

.app-footer-dialog {
  background-color: #596e7c;
  flex-direction: column;
  border-radius: 15px;
  padding: 15px;
}

.home-container {
  flex: auto;
  overflow: auto;
  height: calc(100vh - 215px);
}

@media only screen and (min-width: 768px) {
  .home-container {
    height: calc(100vh - 110px);
  }
}

.main-content-nav-bar {
  background-color: #40b0f2 !important;
  height: auto !important;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-top: 20px;
  min-height: 50px;
}

@media only screen and (min-width: 768px) {
  .main-content-nav-bar {
    background-color: #40b1ef75 !important;
    height: auto !important;
  }
}

.container {
  padding: 10px;
  overflow: auto;
  /* min-height: calc(100vh - 260px); */
  height: calc(100vh - 325px);
}

@media only screen and (min-width: 768px) {
  .container {
    padding: 10px;
    overflow: auto;
    min-height: calc(100vh - 210px);
  }
}

.heading-text {
  margin-bottom: 2rem;
  font-size: 2rem;
}

.heading-text span {
  font-weight: 100;
}

/* Responsive image gallery rules begin*/

.image-gallery {
  /* Mobile first */
  display: flex;
  flex-direction: row;
  gap: 10px;
  flex-wrap: wrap;
}

.image-gallery .column {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  place-content: center;
}

.image-item img {
  /* width: auto; */
  border-radius: 5px;
  /* height: 350px; */
  object-fit: contain;
  width: 150px;
  height: 150px;
  max-width: 150px;
  max-height: 150px;
}

@media only screen and (min-width: 768px) {
  .image-item img {
    width: 300px;
    height: 300px;
    max-width: 300px;
    max-height: 300px;
  }
}

@media only screen and (min-width: 768px) {
  .image-gallery {
    flex-direction: row;
    padding: 0 50px 0 50px;
    justify-content: center;
  }
}

/* overlay styles */

.image-item {
  position: relative;
  cursor: pointer;
}

.overlay {
  position: absolute;
  width: 100%;
  height: 20%;
  background: rgba(57, 57, 57, 0.502);
  bottom: 15px;
  left: 0;
  transform: scale(1);
  transition: all 0.2s 0.1s ease-in-out;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* hover */
/* .image-item:hover .overlay {
  transform: scale(1);
} */

/* ITEM DETAILS */

.dialogHeaderContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.dialogSearchBarHeaderContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: #40b1ef78;
  border-radius: 8px;
  box-shadow: 0px 2px 20px grey;
}

.dialogContentContainer {
  display: flex;
  flex-direction: column;
  width: calc(100vw - 110px);
}

@media only screen and (min-width: 768px) {
  .dialogContentContainer {
    display: flex;
    flex-direction: column;
    width: auto;
  }
}

.dialogImagesListContainer {
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
  justify-content: center;
}

.dialogImagesContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media only screen and (min-width: 768px) {
  .dialogImagesContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .dialogImagesListContainer {
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: column;
  }
}

@media only screen and (min-width: 768px) {
  .dialogImageSelectedItem {
    width: 450px;
    height: 450px;
  }
  .dialogImagesListItem {
    width: 150px;
    height: 150px;
  }
  .dialogImagesListItemSelected {
    width: 150px;
    height: 150px;
  }
}

.dialogImageSelectedItem {
  border-radius: 10px;
  margin: 5px;
}

.dialogImagesListItem {
  width: 100px;
  height: 100px;
  cursor: pointer;
  padding: 5px;
}

.dialogImagesListItemSelected {
  width: 100px;
  height: 100px;
  padding: 5px;
  border-radius: 10px;
  box-shadow: 0px 3px 11px 2px rgba(0, 0, 0, 0.56);
}

#NagishLiBar {
  margin-top: 10vh !important;
}

.mobileMenu {
  position: fixed;
  top: 48px;
  display: flex;
  font-size: 0.5rem;
  background: #58b2ef !important;
  width: 100%;
  max-height: 30px;
  justify-content: space-around;
  z-index: 1299;
  padding: 0px;
}

.mobileMenuHeader {
  position: fixed !important;
  top: 5px;
  right: 20%;
}

.mobileMenuHeaderImage {
  width: 45px;
}

.menuHeaderImage {
  width: 80px;
  margin-top: 20px;
}

.mobileSubCategoriesMenu {
  margin-top: 20px;
  /* display: flex;
  justify-content: space-around;
  font-size: 0.8rem;
  background: white;
  width: 100%;
  flex-wrap: wrap; */
}

.searchBarContainer {
  padding: 10px 10px 10px;
  text-align: -webkit-center;
}
